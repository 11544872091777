<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <h1 >Create Product</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
              v-model="createNoSKU"
              label="Create without SKU"
              outlined
              dense
          />
          <span v-if="!createNoSKU" class="d-flex flex-row justify-space-between align-center">
            <v-text-field
              label="SKU (Model Number)"
              outlined
              v-model="data.sku"
              @focus="lockScanBusForSKUBC"
              @blur="unlockScanBusForSKUBC"
              @change="trimBC"
              :loading="skuValidationLoading"
            />
            <span>
              <v-icon v-if="data.sku&&skuOk" color="green">mdi-check</v-icon>
              <v-icon v-if="data.sku&&!skuOk" color="red">mdi-close</v-icon>
            </span>
          </span>
          <v-text-field
            label="Name"
            outlined
            v-model="data.name"
          />
          <v-text-field
            label="Regular Price"
            outlined
            type="number"
            min="0"
            v-model="data.regularPrice"
          />
          <v-btn color="info" class="mb-3" @click="clear" block large>Clear All</v-btn>
          <v-btn color="success" @click="create" :loading="loader"  :disabled="!this.data.name||(!skuOk&&!createNoSKU)" block large>Create New Product</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar style="z-index: 100; margin-bottom: 60px;" v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      printers: [],
      filteredPrinters: [],
      documentTypes: [],
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      skuValidationLoading: false,
      isEditable: true,
      loader: false,
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {},
      productTypeValues: [
        {name: "Simple", value: "simple"}, {name: "Variable", value: "variable"}
      ],

      isThereVariations: false,

      attributeColors: ['warning', 'info', 'success', 'secondary', 'primary'],

      variationPanels: [],

      selectedCategories: [],
      selectedTags: [],
      selectedSuppliers: [],
      selectedAttributes: [],

      categories: [],
      tags: [],
      images: [],
      attributes: [],
      brands: [],
      suppliers: [],
      variations: [],

      createSupplierDialog: false,
      newSupplier: {},
      supplierLoading: false,

      createBrandDialog: false,
      brandLoading: false,
      newBrand: {},

      createAttributeDialog: false,
      newAttribute: {
        name: "",
        values: []
      },
      attrLoading: false,

      createTermsDialog: false,
      newTerms: {
        attribute: null,
        values: []
      },
      termsLoading: false,

      nameRules: [
        v => !!v || 'Name is required'
      ],

      products: [],

      existingData: [],

      singular: "Inventory",
      singularLower: "inventory",
      plural: "Inventories",
      pluralLower: "inventories",
      printDialog: {
        isOpen: false,
        quantity: 1,
        jobType: 'productLabel',
        deliveryNote: '',
        printer: '',
      },
      skuOk: false,
      createNoSKU: false,
    }
  },
  async mounted(){
    window.scrollTo({top: 0});
    await this.getAllData();
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'getUser', 'scanBus'])
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setScanBus',
      'resetScanBus'
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    clear(){
      this.data = {}
    },
    async trimBC(){
      try {
        this.data.sku = this.data.sku.replace(/\\\/\/\\=/,'')
        console.log('Trimmed')
        this.skuValidationLoading = true
        this.skuOk = await this.skuTaken()
      } catch (error) {
        console.log(error)
        this.snack(error)
      } finally {
        this.skuValidationLoading = false
      }
    },
    async skuTaken(){
      let res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${this.data.sku}`)
      if(res.data.error) return false
      if(!res.data.data) return true
      return false
    },
    lockScanBusForSKUBC(){
      this.setScanBus({handler: "SKU"})
    },
    unlockScanBusForSKUBC(){
      this.setScanBus({data: '.'})
    },
    async create(){
      try {
        this.isEditable = false;
        this.loader = true;

        let obj = {
          ...this.data,
          suppliers: this.selectedSuppliers,
          categories: this.selectedCategories,
          tags: this.selectedTags,
          attributes: this.selectedAttributes,
          variations: this.variations
        };

        obj.regularPrice = parseFloat(obj.regularPrice);
        obj.salePrice = parseFloat(obj.salePrice);
        obj.suppliers.forEach(supplier => {
          supplier.costPrice = parseFloat(supplier.costPrice)
        });
        obj.variations.forEach(variation => {
          variation.regularPrice = parseFloat(variation.regularPrice);
          variation.salePrice = parseFloat(variation.salePrice);
          variation.selectedSuppliers.forEach(supplier => {
            supplier.costPrice = parseFloat(supplier.costPrice)
          });
        });
        if(this.createNoSKU) obj.sku = null
        let res = await axios.post(`${this.getEndpoint}/api/products`, obj)
        if(res.data.error) throw res.data.error

        this.snack("Product Created", "success");
        this.data = {}
        this.createNoSKU = false
      } catch (error) {
        this.isEditable = true;
        console.error(error)
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
      }
    },
    productTypeChanged(){
      this.checkIfVariationsExist();
    },
    openNewAttributeDialog(){
      this.createAttributeDialog = true;
    },
    closeNewAttributeDialog(){
      this.createAttributeDialog = false;
      this.newAttribute = {
        name: "",
        values: []
      }
    },
    async saveAttribute(){
      try{
        this.attrLoading = true;

        let res = await axios.post(`${this.getEndpoint}/api/productattributes/addAttributeWithTerms`, this.newAttribute);
        if(res.data.error) throw res.data.error;

        this.selectedAttributes.push(res.data.data);

        this.closeNewAttributeDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    openNewTermsDialog(i){
      this.createTermsDialog = true;
      this.newTerms = {
        attribute: i,
        values: []
      }
    },
    closeNewTermsDialog(){
      this.createTermsDialog = false;
      this.newTerms = {
        attribute: null,
        values: []
      }
    },
    updateSelectedAttributes(){
      for(let attr of this.selectedAttributes){
        if(attr.selectedTerms===undefined) attr.selectedTerms = [];
        if(attr.variation===undefined) attr.variation = false;
        if(attr.visible===undefined) attr.visible = true;
      }

      for(let variation of this.variations){
        for(let i=variation.selectedAttributes.length-1;i>=0;i--){
          if(!this.selectedAttributes.find(x => x.id===variation.selectedAttributes[i].id)){
            variation.selectedAttributes.splice(i, 1);
          }
        }
      }
    },
    updateSelectedTerms(){
      this.$forceUpdate();
      this.checkIfVariationsExist();
    },
    async saveAttributeTerms(){
      try{
        this.attrLoading = true;

        for(let newTerm of this.newTerms.values){
          let existingTerm = this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms.find(term => {
            return term.name.toLowerCase()===newTerm.toLowerCase();
          });

          if(existingTerm) throw `The term '${existingTerm.name}' already exists in '${this.selectedAttributes[this.newTerms.attribute].name}'`;
        }

        let createdTerms = await axios.post(`${this.getEndpoint}/api/productattributes/addTermsToAttribute/${this.selectedAttributes[this.newTerms.attribute].id}`, {terms: this.newTerms.values});
        if(createdTerms.data.error) throw createdTerms.data.error

        this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms = this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms.concat(createdTerms.data.data);
        this.selectedAttributes[this.newTerms.attribute].selectedTerms = this.selectedAttributes[this.newTerms.attribute].selectedTerms.concat(createdTerms.data.data);

        this.closeNewTermsDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    checkIfVariationsExist(){
      if(this.data.type==="variable"){
        for(let attr of this.selectedAttributes){
          if(attr.variation===true){
            this.isThereVariations = true;
            this.updateVariationTerms();
            this.$forceUpdate();
            return;
          }
        }
      }
      this.isThereVariations = false;
    },
    createVariation(){
      let variation = {
        id: null,
        name: null,
        parent: null,
        sku: "",
        regularPrice: 0.00,
        salePrice: 0.00,
        type: "variation",
        selectedAttributes: [],
        selectedSuppliers: [],
        metadata: {
          customName: false
        }
      };

      this.variations.push(variation);
      this.updateVariationTerms();
      this.updateVariationSuppliers();
    },
    removeVariation(i){
      this.variations.splice(i, 1);
    },
    updateVariationTerms(){
      this.variations.forEach(variation => {
        this.selectedAttributes.forEach(attr => {
          if(attr.variation){
            let found = variation.selectedAttributes.find(x => x.id===attr.id);
            if(!found){
              let obj = {
                id: attr.id,
                name: attr.name,
                selectedTerms: [],
                variation: attr.variation,
                visible: attr.visible,
                selectedTerm: null
              }

              attr.selectedTerms.forEach(x => {
                obj.selectedTerms.push({...x})
              });

              variation.selectedAttributes.push(obj);
            }
            else{
              found.selectedTerms = [];
              attr.selectedTerms.forEach(x => {
                found.selectedTerms.push({...x})
              });
              found.variation = attr.variation;
              found.visible = attr.visible;
            }
          }
        });
        for(let i=variation.selectedAttributes.length-1; i>=0; i--){
          let attr = variation.selectedAttributes[i];
          let found = this.selectedAttributes.find(x => x.id===attr.id);
          if((found && !found.variation) || !found){
            variation.selectedAttributes.splice(i, 1);
          }
        }
      });
    },
    updateVariationSuppliers(){
      if(this.data.type==='variable'){
        this.variations.forEach(variation => {
          let newArr = [];
          this.selectedSuppliers.forEach(supplier => {
            let found = variation.selectedSuppliers.find(x => x.id===supplier.id);
            let cp = 0;
            if(found) cp=found.costPrice;
            newArr.push({id: supplier.id, name: supplier.name, costPrice: cp})
          });
          variation.selectedSuppliers = newArr;
        });
      }
    },
    async getAllData(){
      try {
        this.loader = true;

        let res = await axios.get(`${this.getEndpoint}/api/products/externaldata`);
        if(res.data.error) throw res.data.error

        this.attributes = res.data.data.attributes;
        this.brands = res.data.data.brands;
        this.categories = res.data.data.categories;

        this.suppliers = res.data.data.suppliers;
        this.tags = res.data.data.tags;

      } catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
      }
    },
    closeCreateSupplier(){
      this.createSupplierDialog = false;
      this.newSupplier = {};
      this.supplierLoading = false;
      this.existingData = [];
    },
    async createSupplier(){
      try{
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers`, this.newSupplier)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Supplier Created", "success");
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data;
          this.selectedSuppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
          this.closeCreateSupplier();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Suppliers Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;

      }
    },
    existingSupplierSelected(supplier){
      this.selectedSuppliers.push(supplier.id)
      this.closeCreateSupplier();
    },
    async confirmCreateSupplier(){
      try {
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers/confirmCreate`, this.newSupplier)
        if(res.data.error) throw res.data.error

        this.snack("Supplier Created", "success");
        let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
        if(suppliers.data.error) throw suppliers.data.error
        this.suppliers = suppliers.data.data;
        this.selectedSuppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
        this.closeCreateSupplier();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;
      }
    },
    closeCreateBrand(){
      this.createBrandDialog = false;
      this.newBrand = {};
      this.brandLoading = false;
      this.existingData = [];
    },
    async createBrand(){
      try{
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands`, this.newBrand)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Brand Created", "success");
          let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
          if(brands.data.error) throw brands.data.error
          this.brands = brands.data.data;
          this.data.brandId = res.data.data.data.id;
          this.closeCreateBrand();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Brands Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    existingBrandSelected(brand){
      this.data.brandId = brand.id;
      this.closeCreateBrand();
    },
    async confirmCreateBrand(){
      try {
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands/confirmCreate`, this.newBrand)
        if(res.data.error) throw res.data.error

        this.snack("Brand Created", "success");
        let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
        if(brands.data.error) throw brands.data.error
        this.brands = brands.data.data;
        this.data.brandId = res.data.data.data.id;
        this.closeCreateBrand();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    resetExisting(){
      this.existingData = [];
    }
  }
}
</script>